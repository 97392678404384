import "./App.css"
import { Admin, Layout, Menu, Resource, AppBar, MenuItemLinkProps, GetListParams, DataProvider, useRefresh } from "react-admin"
import buildHasuraProvider from "ra-data-hasura"
import { Fragment, useEffect, useState } from "react"
import { ApolloClient, InMemoryCache, NormalizedCacheObject, DefaultOptions } from "@apollo/client"
import { ActualPayouts } from "./payouts/ActualPayouts"
import { RevertPayout } from "./forms/RevertForm"
import { customBuildFields } from "./hasura"
import { useAuth0 } from "@auth0/auth0-react"
import { Auth0Provider } from "@auth0/auth0-react"
import { ENV } from "./configs/env"
import { KolProfilesList } from "./features/KolsList"
import { KolProfileForm } from "./forms/KolProfileForm"
import { ApolloProviderComposer } from "./providers/apollo"
import { ToastContainer } from "react-toastify"
import { MerchantsList } from "./features/MerchantsList"
import { MerchantForm } from "./forms/MerchantForm"
import { GET_TRANSACTION_HISTORY, GET_ADJUSTMENT_TRANSACTIONS, GET_KOLS, GET_WALLET_ID_BY_KOL_ID, GET_KOLS_BY_WALLET_ID, GET_CAMPAIGNS_IDS_BY_TXN_IDS, GET_CAMPAIGN_INFO_BY_IDS, GET_DOCUMENTS_IDS_BY_TXN_IDS, GET_DOCUMENTS_BY_IDS, GET_TRANSACTION_BY_IDS, GET_TRANCHE_INFO_BY_CAMPAIGN_IDS } from "./queries/transaction"
import { ResourceType } from "./constants"
import { useRole } from "./hooks/useRole"
import { CampaignsList } from "./features/CampaignsList"
import { CampaignForm } from "./forms/CampaignForm"
import { ReactComponent as IconKols } from "./assets/ic_kols.svg"
import { ReactComponent as IconPendingPayout } from "./assets/ic_pending_payout.svg"
import { ReactComponent as IconTransactions } from "./assets/ic_transactions.svg"
import { ReactComponent as IconMerchants } from "./assets/ic_merchants.svg"
import { ReactComponent as IconCampaigns } from "./assets/ic_campaign.svg"
import { ReactComponent as IconKolsInActive } from "./assets/ic_kols-inactive.svg"
import { ReactComponent as IconPendingPayoutInActive } from "./assets/ic_pending_payout-inactive.svg"
import { ReactComponent as IconTransactionsInActive } from "./assets/ic_transactions-inactive.svg"
import { ReactComponent as IconMerchantsInActive } from "./assets/ic_merchants-inactive.svg"
import { ReactComponent as IconCampaignsInActive } from "./assets/ic_campaign-inactive.svg"
import { useLocation } from "react-router-dom"
import PendingPayouts2 from "./payouts/PendingPayouts2"

const createApolloClient = (idToken: string) => {
  console.info(`[createApolloClient]`, { idToken })
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  return new ApolloClient({
    uri: ENV.REACT_APP_APOLLO_GRAPHQL_URL,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
}

export const MyMenu = () => {
  const userRole = useRole()
  const location = useLocation()
  const isActive = (path: string) => location.pathname === path
  return (
    <Menu>
      {userRole === "accountant" && (
        <Fragment>
          <Menu.DashboardItem
            to="/kol_profile"
            primaryText="Influencers"
            leftIcon={isActive("/kol_profile") ? <IconKols /> : <IconKolsInActive />}
          />
          <Menu.DashboardItem
            to="/kol_payout"
            primaryText="Pending Payouts"
            leftIcon={isActive("/kol_payout") ? <IconPendingPayout /> : <IconPendingPayoutInActive />}
          />
          <Menu.DashboardItem
            to="/view_transaction_for_payout"
            primaryText="Transactions Log"
            leftIcon={isActive("/view_transaction_for_payout") ? <IconTransactions /> : <IconTransactionsInActive />}
          />
        </Fragment>
      )}

      {userRole === "campaign_manager" && (
        <Fragment>
          <Menu.DashboardItem
            to="/kol_profile"
            primaryText="Influencers"
            leftIcon={isActive("/kol_profile") ? <IconKols /> : <IconKolsInActive />}
          />
          <Menu.DashboardItem
            to="/client_profile"
            primaryText="Merchants"
            leftIcon={isActive("/client_profile") ? <IconMerchants /> : <IconMerchantsInActive />}
          />
          <Menu.DashboardItem
            to="/campaign"
            primaryText="Campaigns"
            leftIcon={isActive("/campaign") ? <IconCampaigns /> : <IconCampaignsInActive />}
          />
        </Fragment>
      )}
    </Menu>
  )
}

function roleToText(role: any) {
  if (role === "accountant") {
    return "Accountant"
  } else if (role === "campaign_manager") {
    return "Platform Manager"
  } else {
    return ""
  }
}

const MyAppBar = () => {
  const { user, logout } = useAuth0()
  const role = useRole()

  return (
    <AppBar>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span style={{ fontSize: 20 }}>KOLs.Asia</span>
        {user && (
          <div>
            <span style={{ marginRight: 10 }}>
              Hi, {user?.name} {roleToText(role) ? `(${roleToText(role)})` : ""}
            </span>
            <button onClick={() => logout({ returnTo: window.location.origin })}>Log out</button>
          </div>
        )}
      </div>
    </AppBar>
  )
}

export const MyLayout = (props: any) => <Layout appBar={MyAppBar} {...props} menu={MyMenu} />

export const Entrance = () => {
  return (
    <Auth0Provider
      domain={ENV.REACT_APP_AUTH0_DOMAIN}
      clientId={ENV.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
    >
      <ApolloProviderComposer>
        <AuthProvider />
        <ToastContainer />
      </ApolloProviderComposer>
    </Auth0Provider>
  )
}

async function getKolsByWalletIds(client: ApolloClient<NormalizedCacheObject>, walletIds: Array<any>) {
  const walletIdResponse = await client.query({
    query: GET_KOLS_BY_WALLET_ID,
    variables: {
      wallet_ids: walletIds
    }
  });
  return walletIdResponse?.data?.kol_profile;
}

async function getDocumentsByIdsByTnxIds(client: ApolloClient<NormalizedCacheObject>, tnxIds: Array<any>) {
  const documentIdsResponse = await client.query({
    query: GET_DOCUMENTS_IDS_BY_TXN_IDS,
    variables: {
      ids: tnxIds
    }
  });

  let result = documentIdsResponse.data.transaction

  const documentIds = documentIdsResponse.data.transaction.map((transaction: any) => transaction.document_id).filter((documentId: any) => documentId !== null && documentId !== '' && documentId !== undefined);

  const documentResponse = await client.query({
    query: GET_DOCUMENTS_BY_IDS,
    variables: {
      ids: documentIds
    }
  });
  result = result.map((transaction: any) => {
    const document = documentResponse.data.file_metadata.find((doc: any) => doc.id === transaction.document_id);
    return {
      ...transaction,
      document_name: document ? document.original_name : "",
      document_link: document ? document.url : "",
      document_id: document ? document.id : ""
    };
  })
  return result;
}

async function getCampaignsInfoByTxnIds(client: ApolloClient<NormalizedCacheObject>, txnIds: Array<any>) {
  const txnResponse = await client.query({
    query: GET_CAMPAIGNS_IDS_BY_TXN_IDS,
    variables: {
      ids: txnIds
    }
  });
  const campaignIds = txnResponse?.data?.transaction.map((transaction: any) => transaction.campaign_id).filter((campaignId: any) => campaignId !== null);
  const campaignInfoResponse = await client.query({
    query: GET_CAMPAIGN_INFO_BY_IDS,
    variables: {
      ids: campaignIds
    }
  });
  const trancheInfoResponse = await client.query({
    query: GET_TRANCHE_INFO_BY_CAMPAIGN_IDS,
    variables: {
      campaignIds: campaignIds
    }
  });
  let result = []
  for (let i = 0; i < txnResponse?.data?.transaction.length; i++) {
    const txn = txnResponse?.data?.transaction[i];
    const campaigns = campaignInfoResponse?.data?.campaign.filter((campaign: any) => campaign.id === txn.campaign_id || campaign.id === txn.reference_transaction_id);

    let campaignInfo = ""
    for (let j = 0; j < campaigns.length; j++) {
      const tranches = trancheInfoResponse?.data?.tranche.filter((tranche: any) => campaigns[j].id === tranche.campaign_id);
      campaignInfo += campaigns[j].name + " | " + tranches.map((tranche: any) => tranche.name).join(" | ");
      if (j < campaigns.length - 1) {
        campaignInfo += " - ";
      }
    }
    result.push({
      ...txn,
      campaign_id: campaigns.map((campaign: any) => campaign.id).join(","),
      campaign_name: campaignInfo
    });
  }

  return result;
}

async function getAdjustedTransactions(client: ApolloClient<NormalizedCacheObject>, txn_ids: Array<any> = []) {
  const adjustmentTransactions = await client.query({
    query: GET_ADJUSTMENT_TRANSACTIONS,
  })

  const normalTransactions = await client.query({
    query: GET_TRANSACTION_BY_IDS,
    variables: {
      ids: txn_ids
    }
  })

  const kols = await client.query({
    query: GET_KOLS,
  })

  let adjustmentTransactionsResult = adjustmentTransactions.data.transaction.map((transaction: any) => {
    const kol = kols.data.kol_profile.find(
      (kol: any) => kol.wallet_id === transaction.debit || kol.wallet_id === transaction.credit,
    )
    return {
      ...transaction,
      individual_fullname: kol ? kol.individual_fullname : "N/A",
      kol_wallet_id: kol ? kol.wallet_id : "N/A",
      kol_name: `${kol ? kol.first_name : ""}` + ' ' + `${kol ? kol.last_name : ""}`,
    }
  })
  adjustmentTransactionsResult = (adjustmentTransactionsResult as Array<any>).filter((transaction: any) => transaction.individual_fullname !== "N/A")
  let normalTransactionsResult = normalTransactions.data.transaction.map((transaction: any) => {
    const kol = kols.data.kol_profile.find(
      (kol: any) => kol.wallet_id === transaction.debit || kol.wallet_id === transaction.credit,
    )
    return {
      ...transaction,
      individual_fullname: kol ? kol.individual_fullname : "N/A",
      kol_wallet_id: kol ? kol.wallet_id : "N/A",
      kol_name: `${kol ? kol.first_name : ""}` + ' ' + `${kol ? kol.last_name : ""}`,
    }
  })
  normalTransactionsResult = (normalTransactionsResult as Array<any>).filter((transaction: any) => transaction.individual_fullname !== "N/A")
  const combinedTransactions = [
    ...adjustmentTransactionsResult,
    ...normalTransactionsResult
  ];
  return combinedTransactions
}

const App = ({ token }: { token: string }) => {
  const [dataProvider, setDataProvider] = useState(null)
  const userRole = useRole()

  const buildDataProvider = async () => {
    const client = createApolloClient(token)

    const dataProvider = await buildHasuraProvider(
      {
        client,
      },
      { buildFields: customBuildFields },
    )

    setDataProvider((introspection): any => {
      console.info(`[App]`, { introspection, dataProvider })
      return {
        ...dataProvider,
        getList: async (resource: any, params: any) => {
          if (resource === ResourceType.kol_profile) {
            // Custom multi-field search logic
            // const { filter } = params || {};
            // const { q = "" } = filter || {};
            var paymentInfo = new Array()
            var result = await dataProvider.getList(resource, params)
            for (var i = 0; i < result.data.length; i++) {
              const element = result.data[i]
              const kolPayoutParams = {
                pagination: {
                  page: 1,
                  perPage: 10000,
                },
                sort: {
                  field: "total_amount",
                  order: "DESC" as const,
                },
                filter: {
                  kol_profile_id: element.id,
                },
              }
              //Get payouts
              var kolPayouts = (await dataProvider.getList("kol_payout", kolPayoutParams)).data
              if (kolPayouts.length == 0) {
                element.payouts = []
              } else {
                //get payout transactions
                const kolTransactionParams = {
                  pagination: {
                    page: 1,
                    perPage: 10000,
                  },
                  sort: {
                    field: "timestamp",
                    order: "DESC" as const,
                  },
                  filter: {
                    credit: kolPayouts.map((payoutElement) => payoutElement.id),
                    type: "KOL_PAYOUT",
                  },
                }
                const transactions = await dataProvider.getList("transaction", kolTransactionParams)
                element.payouts = transactions.data
              }
              paymentInfo.push(element)
            }

            return {
              data: paymentInfo,
              total: paymentInfo.length,
            }
          } else if (resource === ResourceType.kol_payout) {
            var kolPayouts = (await dataProvider.getList("kol_payout", params)).data;
            return {
              data: kolPayouts,
              total: kolPayouts.length,
            }
          } else if (resource === ResourceType.myTransactionHistory) {
            const { profile_id } = params?.filter || {}

            if (!profile_id) {
              return {
                data: [],
                total: 0,
              }
            }

            const resp = await client.query({
              query: GET_TRANSACTION_HISTORY,
              variables: {
                profile_id,
                profile_type: "MERCHANT",
              },
            })

            const list = resp?.data?.myTransactionHistory || []

            return {
              data: list,
              total: list.length,
            }
          }
          else if (resource === ResourceType.viewTransactionForPayout) {
            const kolTransactionParams = {
              pagination: {
                page: 1,
                perPage: 10000
              },
              sort: {
                field: "individual_fullname",
                order: 'ASC' as const
              },

            }
            const data = await dataProvider.getList(resource, kolTransactionParams as GetListParams);
            const adjustmentTransactions = await getAdjustedTransactions(client, data.data.map((transaction: any) => transaction.id));
            var list = Array();
            // data.data.forEach(element => {
            //   list.push(element);
            // });
            adjustmentTransactions.forEach(element => {
              list.push(element);
            });
            if (params.filter.type != '') {
              list = list.filter((transaction: any) => transaction.type === params.filter.type);
            }
            if (params.filter.kol_id != '') {
              const walletIdResponse = await client.query({
                query: GET_WALLET_ID_BY_KOL_ID,
                variables: {
                  kol_id: params.filter.kol_id
                }
              });
              const walletId = walletIdResponse?.data?.kol_profile[0]?.wallet_id;
              list = list.filter((transaction: any) => transaction.kol_wallet_id === walletId);
            }
            list.sort((a: any, b: any) => {
              return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            });

            const walletIds = list.map((transaction: any) => transaction.kol_wallet_id);
            const kols = await getKolsByWalletIds(client, walletIds);
            let includeRefTxnIds = [...list.map((transaction: any) => transaction.id), ...list.filter((transaction: any) => transaction.reference_transaction_id).map((transaction: any) => transaction.reference_transaction_id)];
            const campaignInfo = await getCampaignsInfoByTxnIds(client, includeRefTxnIds);
            list = list.map((transaction: any) => {
              const kol = kols.find((kol: any) => kol.wallet_id === transaction.kol_wallet_id);
              const campaignName = campaignInfo.find((c: any) => c.id === transaction.id)?.campaign_name ?? ""
              return {
                ...transaction,
                bank_name: kol ? kol.bank_name : "",
                bank_account_no: kol ? kol.bank_account_no : "",
                campaign_info: transaction.type === 'KOL_TRANSFER' || transaction.type === 'KOL_TRANSFER_REVERT' || campaignName === '' ? transaction.description : campaignInfo.find((c: any) => c.id === transaction.id)?.campaign_name ?? "",
                campaign_id: campaignInfo.find((c: any) => c.id === transaction.id)?.campaign_id ?? "",
              };
            })

            const documents = await getDocumentsByIdsByTnxIds(client, list.map((transaction: any) => transaction.id));
            list = list.map((transaction: any) => {
              const document = documents.find((doc: any) => doc.id === transaction.id);
              return {
                ...transaction,
                document_name: document ? document.document_name : "",
                document_link: document ? document.document_link : "",
                document_id: document ? document.document_id : ""

              };
            })
            localStorage.setItem('transactions', JSON.stringify(list));
            return {
              data: getSubArray(list, (params.pagination.page - 1) * params.pagination.perPage, params.pagination.perPage),
              total: list.length
            };
          } else {
            // Default behavior for other resources
            return dataProvider.getList(resource, params)
          }
        },
      }
    })
  }

  useEffect(() => {
    buildDataProvider()
  }, [token])

  useEffect(() => {
    console.info(`[ENV]`, process.env)
  }, [])

  if (!dataProvider) {
    return <div>No data provider</div>
  }

  if (!userRole) {
    return null
  }

  return (
    <Admin layout={MyLayout} dataProvider={dataProvider as any}>
      {(userRole === "accountant" || userRole === "backoffice") && (
        <Fragment>
          <Resource name="kol_profile" list={KolProfilesList} edit={KolProfileForm} />
          <Resource
            name="kol_payout"
            list={PendingPayouts2}
          />
          <Resource name="view_transaction_for_payout" list={ActualPayouts} edit={RevertPayout} />
        </Fragment>
      )}

      {userRole === "campaign_manager" && (
        <Fragment>
          <Resource name="kol_profile" list={KolProfilesList} edit={KolProfileForm} />
          <Resource name="client_profile" list={MerchantsList} edit={MerchantForm} />
          <Resource name="campaign" list={CampaignsList} edit={CampaignForm} />
        </Fragment>
      )}
    </Admin>
  )
}

const AuthProvider: any = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect, logout, getIdTokenClaims } = useAuth0()

  const [token, setToken] = useState<null | string>(null)

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((idToken) => {
        console.info(`[AuthProvider] got ID token ${JSON.stringify(idToken)}`)

        setToken(idToken?.__raw as string)
      })
    }
  }, [isAuthenticated, token])

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      console.log("Redirect")
        ; (async () => {
          await loginWithRedirect()
        })()
    }
  }, [isLoading])

  const onTryAgain = () => {
    logout({ returnTo: window.location.origin })
  }

  console.info(`[AuthProvider]`, { isAuthenticated, isLoading, error, token })

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return (
      <div>
        <h1>Oops... {error.message}</h1>
        <button onClick={onTryAgain}>TRY AGAIN</button>
      </div>
    )
  }

  return <div>{!token ? <div>Getting token...</div> : <App token={token} />}</div>
}

function getSubArray<T>(array: T[], fromIndex: number, lengthOfSubArray: number): T[] {
  let endIndex = fromIndex + lengthOfSubArray;
  const subArray = array.slice(fromIndex, endIndex);
  return subArray;
}